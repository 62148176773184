import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import RightArrowBoxSVG from './svgs/RightArrowBox.svg';

const RightArrowBox = (props: SvgIconProps) => {
    return <SvgIcon viewBox="0 0 20 20" {...props} component={RightArrowBoxSVG} />;
};

export default RightArrowBox;
