import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import SpeakerIcon from './svgs/Speaker.svg';

const Speaker = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 21 20" component={SpeakerIcon} />;
};

export default Speaker;
