import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import React from 'react';
import CircleCheckmarkThickOutlineSVG from './svgs/CircleCheckmarkThickOutline.svg';
import CircleCheckmarkThinOutlineSVG from './svgs/CircleCheckmarkThinOutline.svg';

type CircleCheckmarkOutlineProps = SvgIconProps & {
    strokeWidth?: 'thin' | 'thick';
};

const CircleCheckmarkOutline = (props: CircleCheckmarkOutlineProps) => {
    const { strokeWidth = 'thin' } = props;

    return (
        <SvgIcon
            {...props}
            viewBox="0 0 24 24"
            component={
                strokeWidth === 'thin'
                    ? CircleCheckmarkThinOutlineSVG
                    : CircleCheckmarkThickOutlineSVG
            }
        />
    );
};

export default CircleCheckmarkOutline;
