import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import HamburgerSVG from './svgs/Hamburger.svg';

const Hamburger = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 20 20" component={HamburgerSVG} />;
};

export default Hamburger;
