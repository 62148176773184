import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import AccountDefaultSVG from './svgs/AccountDefault.svg';

const AccountDefault = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 52 52" component={AccountDefaultSVG} />;
};

export default AccountDefault;
