import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import AISVG from './svgs/ai.svg';

const AI = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 39 39" component={AISVG} />;
};

export default AI;
