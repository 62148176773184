import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import LightBulbOutlineSVG from './svgs/LightBulbOutline.svg';

const LightBulbOutline = (props: SvgIconProps) => {
    return <SvgIcon viewBox="0 0 22 23" {...props} component={LightBulbOutlineSVG} />;
};

export default LightBulbOutline;
