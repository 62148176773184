import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import WarningSVG from './svgs/Warning.svg';

const Warning = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={WarningSVG} viewBox="0 0 25 23" />;
};

export default Warning;
