import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import React from 'react';
import CheckmarkSVG from './svgs/Checkmark.svg';

const Checkmark = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 12 9" component={CheckmarkSVG} />;
};

export default Checkmark;
