import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import SeedlingIcon from './svgs/Seedling.svg';

const Seedling = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={SeedlingIcon} viewBox="0 0 30 30" />;
};

export default Seedling;
