import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { SvgIconProps } from '@mui/material';
import RightArrow from './svgs/RightArrow.svg';

const RightArrowIcon = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={RightArrow} viewBox="0 0 16 16" />;
};

export default RightArrowIcon;
