import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import WarningOutlineIcon from './svgs/WarningOutline.svg';

const WarningOutline = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={WarningOutlineIcon} viewBox="0 0 53 48" />;
};

export default WarningOutline;
