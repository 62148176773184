import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import SwipeHandSVG from './svgs/OverlayIcons/SwipeHand.svg';

const SwipeHand = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 151 151" component={SwipeHandSVG} />;
};

export default SwipeHand;
