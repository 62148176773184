import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import ArrowCircleIcon from './svgs/ArrowCircle.svg';

const ArrowCircle = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={ArrowCircleIcon} viewBox="0 0 35 35" />;
};

export default ArrowCircle;
