import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import ExitSVG from './svgs/Exit.svg';

const Exit = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 12 12" component={ExitSVG} />;
};

export default Exit;
