import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import ChevronSVG from './svgs/Chevron.svg';

const Chevron = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 512 512" component={ChevronSVG} />;
};

export default Chevron;
