import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import ListSVG from './svgs/List.svg';

const List = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 18 15" component={ListSVG} />;
};

export default List;
