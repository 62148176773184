import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import SendSVG from './svgs/Send.svg';

const Send = (props: SvgIconProps) => {
    return <SvgIcon viewBox="0 0 23 25" {...props} component={SendSVG} />;
};

export default Send;
