import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import QuestionMarkCircleChatBubbleIcon from './svgs/QuestionMarkCircleChatBubble.svg';

const QuestionMarkCircleChatBubble = (props: SvgIconProps) => {
    return (
        <SvgIcon
            {...props}
            component={QuestionMarkCircleChatBubbleIcon}
            viewBox="0 0 30 30"
        />
    );
};

export default QuestionMarkCircleChatBubble;
