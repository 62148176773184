import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import SquareRootSymbolSVG from './svgs/SquareRootSymbol.svg';

const SquareRootSymbol = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 23 16" component={SquareRootSymbolSVG} />;
};

export default SquareRootSymbol;
