import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import ResultsSVG from './svgs/Results.svg';

const Results = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 29 29" component={ResultsSVG} />;
};

export default Results;
