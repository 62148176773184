import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import QuestionMarkCircleSVG from './svgs/QuestionMarkCircle.svg';

const QuestionMarkCircle = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 21 21" component={QuestionMarkCircleSVG} />;
};

export default QuestionMarkCircle;
