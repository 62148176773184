import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import ExploreSVG from './svgs/Explore.svg';

const Explore = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 30 30" component={ExploreSVG} />;
};

export default Explore;
