import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import GearSVG from './svgs/Gear.svg';

const Gear = (props: SvgIconProps) => {
    return <SvgIcon viewBox="0 0 19 20" {...props} component={GearSVG} />;
};

export default Gear;
