import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import QuestionMarkChatBubbleSVG from './svgs/QuestionMarkChatBubble.svg';

const QuestionMarkChatBubble = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 38 37" component={QuestionMarkChatBubbleSVG} />
    );
};

export default QuestionMarkChatBubble;
