import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import ExclamationSVG from './svgs/Exclamation.svg';

const Exclamation = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 139 139" component={ExclamationSVG} />;
};

export default Exclamation;
