import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import MicIcon from './svgs/Mic.svg';

const Mic = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 10 13" component={MicIcon} />;
};

export default Mic;
