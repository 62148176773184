import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import CloseSVG from './svgs/Close.svg';

const Close = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 32 32" component={CloseSVG} />;
};

export default Close;
