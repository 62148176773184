import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import ExitLeftSVG from './svgs/ExitLeft.svg';

const Exit = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 30 30" component={ExitLeftSVG} />;
};

export default Exit;
