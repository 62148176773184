import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import Logo from './svgs/Logo.svg';

const LogoIcon = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={Logo} viewBox="0 0 59 67" />;
};

export default LogoIcon;
