import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import InfoBubbleSVG from './svgs/InfoBubble.svg';

const InfoBubble = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 30 30" component={InfoBubbleSVG} />;
};

export default InfoBubble;
