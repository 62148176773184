import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import PencilSVG from './svgs/Pencil.svg';

const Pencil = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 14 14" component={PencilSVG} />;
};

export default Pencil;
