import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import StarPersonSVG from './svgs/StarPerson.svg';

const StarPerson = (props: SvgIconProps) => {
    return <SvgIcon viewBox="0 0 117 164" {...props} component={StarPersonSVG} />;
};

export default StarPerson;
