import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import React from 'react';
import QRCodeSVG from './svgs/QRCode.svg';

const QRCode = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 12 12" component={QRCodeSVG} />;
};

export default QRCode;
