import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import BrainSVG from './svgs/Brain.svg';

const Brain = (props: SvgIconProps) => {
    return <SvgIcon viewBox="0 0 64 64" {...props} component={BrainSVG} />;
};

export default Brain;
