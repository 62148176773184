import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import VerticalThreeDots from './svgs/VerticalThreeDots.svg';

const VerticalThreeDotsIcon = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={VerticalThreeDots} viewBox="0 0 6 21" />;
};

export default VerticalThreeDotsIcon;
