import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import CircleXSVG from './svgs/CircleX.svg';
import CircleXThinSVG from './svgs/CircleXThin.svg';

interface CircleXProps extends SvgIconProps {
    strokeWidth?: 'thin' | 'thick';
}

const CircleX = (props: CircleXProps) => {
    const { strokeWidth = 'thick' } = props;

    return (
        <SvgIcon
            {...props}
            viewBox="0 0 20 20"
            component={strokeWidth === 'thin' ? CircleXThinSVG : CircleXSVG}
        />
    );
};

export default CircleX;
