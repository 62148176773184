import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import DuplicateSVG from './svgs/Duplicate.svg';

const Duplicate = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 18 18" component={DuplicateSVG} />;
};

export default Duplicate;
