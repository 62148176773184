import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import NeitherSVG from './svgs/Niether.svg';

const Neither = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 20 20" component={NeitherSVG} />;
};

export default Neither;
