import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import LanguageSVG from './svgs/Language.svg';

const Language = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 14 13" component={LanguageSVG} />;
};

export default Language;
