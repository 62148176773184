import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import MagnifyingGlassSVG from './svgs/MagnifyingGlass.svg';

const MagnifyingGlass = (props: SvgIconProps) => {
    return <SvgIcon viewBox="0 0 21 21" {...props} component={MagnifyingGlassSVG} />;
};

export default MagnifyingGlass;
