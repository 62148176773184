import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import PlusFilledSVG from './svgs/PlusFilled.svg';

const PlusFilled = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={PlusFilledSVG} viewBox="0 0 10 10" />;
};

export default PlusFilled;
