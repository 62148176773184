import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import ExternalLinkSVG from './svgs/ExternalLink.svg';

const ExternalLink = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={ExternalLinkSVG} viewBox="0 0 20 20" />;
};

export default ExternalLink;
