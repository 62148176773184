import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import MagicWandSVG from './svgs/MagicWand.svg';

const MagicWand = (props: SvgIconProps) => {
    return <SvgIcon viewBox="0 0 140 169" {...props} component={MagicWandSVG} />;
};

export default MagicWand;
