import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import CopyLinkSVG from './svgs/CopyLink.svg';

const CopyLink = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 36 30" component={CopyLinkSVG} />;
};

export default CopyLink;
