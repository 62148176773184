import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import UploadIcon from './svgs/Upload.svg';

const Upload = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={UploadIcon} viewBox="0 0 56 68" />;
};

export default Upload;
