import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import PaperclipSVG from './svgs/Paperclip.svg';

const Paperclip = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 512.001 512.001" component={PaperclipSVG} />;
};

export default Paperclip;
