import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import BackArrowSVG from './svgs/BackArrow.svg';

const BackArrow = (props: SvgIconProps) => {
    return <SvgIcon viewBox="0 0 19 19" {...props} component={BackArrowSVG} />;
};

export default BackArrow;
