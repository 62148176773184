import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import LogoText from './svgs/LogoText.svg';

const LogoTextIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            {...props}
            // Fill none is necessary, otherwise the bottom tip of the chat bubble will be filled
            sx={{ fill: 'none' }}
            viewBox="0 0 744 133"
            component={LogoText}
        />
    );
};

export default LogoTextIcon;
