import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import TrashSVG from './svgs/Trash.svg';

const Trash = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 12 14" component={TrashSVG} />;
};

export default Trash;
