import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import RocketShipSVG from './svgs/RocketShip.svg';

const RocketShip = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={RocketShipSVG} viewBox="0 0 20 23" />;
};

export default RocketShip;
