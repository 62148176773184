import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';
import StarsSVG from './svgs/Stars.svg';

const Stars = (props: SvgIconProps) => {
    return <SvgIcon {...props} viewBox="0 0 29 27" component={StarsSVG} />;
};

export default Stars;
